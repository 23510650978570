'use client';

import { useLocale, useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { usePathname } from '@/i18n/navigation';
import { LOCALES, LOCALES_TO_HIDE } from '@/utils/constants';
import { COOKIE_KEY_LOCALE, setCookieByKey } from '@/utils/cookies';
import { Locale } from '@/utils/type-utils';

import ChevronIcon from '../icons/ChevronIcon';
import Title from './ListTitle';
import Section from './Section';
import { LANGUAGES_MAP } from './languages';

import clsx from 'clsx';

const FooterListItemLink = dynamic(() => import('./ListItemLink'), {
  ssr: false,
});

export default function LanguageSection() {
  const locale = useLocale();
  const t = useTranslations('footer');
  const pathname = usePathname();

  const [isExtended, setIsExtended] = useState(false);
  const otherLanguages =
    LOCALES?.filter(
      (l: string) => l !== locale && !LOCALES_TO_HIDE.includes(l)
    ) || [];

  const handleToggleSelect = () => {
    setIsExtended((previousValue) => !previousValue);
  };

  return (
    <Section>
      <Title>{t('language')}</Title>

      <div>
        <div
          className="flex items-center gap-1 mb-4 cursor-pointer"
          onClick={handleToggleSelect}
        >
          <ChevronIcon
            className={clsx(isExtended && 'rotate-180', 'stroke-white')}
          />
          {LANGUAGES_MAP.get(locale || 'en')}
        </div>
        {isExtended && (
          <ul
            className="flex flex-col ml-4 gap-y-4"
            onClick={handleToggleSelect}
          >
            {otherLanguages.map((l: Locale) => (
              <FooterListItemLink
                href={pathname}
                dataTestId={`link-lang-${l}`}
                key={l}
                locale={l}
                onClick={() => setCookieByKey(COOKIE_KEY_LOCALE, l)}
              >
                {LANGUAGES_MAP.get(l)}
              </FooterListItemLink>
            ))}
          </ul>
        )}
      </div>
    </Section>
  );
}
